@import url("https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,400,300,600,700|Roboto+Slab:400,300,700");
/*
 * General styles
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

header, footer, section, nav {
  display: block; }

html, body {
  height: 100%; }

body {
  font-family: 'Open Sans', Verdana, Geneva, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  color: #717171;
  background: #FFFFFF; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Slab', serif; }

a:link,
a:visited {
  text-decoration: none;
  color: #717171; }

.wrapper {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  align-items: center; }

header {
  padding: 20px; }

.container {
  width: 768px; }
  .container.container-footer {
    padding: 20px 36px;
    display: flex;
    flex-direction: column; }

.logo img {
  border: none;
  max-width: 100%;
  margin-bottom: 12px;
  width: 240px; }

.content {
  display: flex;
  justify-content: center; }

.section:not(:empty) {
  width: 50%;
  margin: 0 24px; }
  .section:not(:empty).login {
    padding: 12px; }

footer {
  color: #FFFFFF;
  font-size: 11px;
  background: #006633;
  height: 10rem;
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); }
  footer address {
    font-style: normal;
    display: block; }
  footer a:link, footer a:visited {
    color: #FFCC33; }

.footer-text {
  margin-bottom: 12px; }

.footer-links a:link,
.footer-links a:visited {
  color: #FFFFFF;
  font-weight: bold; }

.footer-links a:after {
  content: "\00a0\00a0\00a0|\00a0\00a0"; }

.footer-links a.last:after {
  content: ""; }

.d-none {
  display: none; }

.mt-4 {
  margin-top: 32px; }

.alert.alert-motd {
  padding: 8px;
  background: #FFCC33;
  color: #000; }
  .alert.alert-motd h3.alert-heading {
    margin-bottom: 8px;
    font-size: 14px; }
  .alert.alert-motd p.alert-body {
    margin-bottom: 8px;
    font-size: 10px; }
    .alert.alert-motd p.alert-body a {
      color: #425195; }
  .alert.alert-motd small.alert-date {
    font-size: 8px; }

@media only screen and (max-width: 767.99px) {
  .container {
    width: 540px; }
  .section.login {
    padding: 0px; }
  .section.links .alert-motd {
    display: none; }
  .section.links .list-help {
    margin: 0 0 20px 0; }
  .content {
    flex-direction: column;
    align-items: center; }
  header img {
    display: block;
    margin: 0 auto; }
  footer {
    justify-content: center; }
    footer .container.container-footer {
      width: 50%;
      padding: 20px 0; } }

@media only screen and (min-width: 768px) {
  .section.motd {
    display: none; } }

form {
  padding-bottom: 21px; }

form label {
  /* labels are hidden */
  font-weight: bold; }

form legend {
  font-size: 1.2em;
  margin-bottom: 12px; }

.form-element-wrapper {
  margin-bottom: 12px; }

.form-element {
  width: 100%;
  padding: 13px 12px;
  border: none;
  font-size: 14px;
  border-radius: 4px; }

.form-field {
  color: #B7B7B7;
  border: 1px solid #B7B7B7; }

.form-field-focus,
.form-field:focus,
input[type="text"]:focus {
  color: #333333;
  border-color: #333333; }

.form-button {
  background: #AC1D37;
  color: #FFFFFF;
  cursor: pointer; }

.form-button:hover {
  background: #F7941E; }

.form-error {
  padding: 0;
  color: #AC1D37; }

.list-help {
  margin-top: 40px;
  /* offset padding on first anchor */
  list-style: none; }

.list-help-item a {
  display: block;
  padding: 6px 0; }

.item-marker {
  color: #AC1D37; }
